<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  padding: 160px 0;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding-bottom: 28px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 20.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 79%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    border: 1px solid #999;
    border-radius: 0px;
    background-color: #0f1118;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .button-wrapper {
    width: 50%;
    margin: 0 auto;
    display: flex;
  }
  .button {
    width: 140px;
    height: 36px;
    text-align: center;
    overflow: hidden;
    line-height: 36px;
    margin: 30px auto 0;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 18px;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            企业信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业名称
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p> {{demandObj.company_name}}</p>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业简介
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p> {{demandObj.company_description}}</p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            赛事信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              赛题名称
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p> {{demandObj.title}}</p>
          </div>
        </div>
      </div>

      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            设计需求
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{demandObj.design_type}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>{{demandObj.design_type2}}</p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            入驻企业/项目需求
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{demandObj.project_type}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>
              {{demandObj.project_type2}}
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            奖项设置
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          孵化基金 <span :style="{margin: '0 6px'}"
                v-if="demandObj.detail"> {{ demandObj.detail.mon1 }} </span> 元/项目,总计拟 <span :style="{margin: '0 6px'}"
                v-if="demandObj.detail"> {{ demandObj.detail.mon2 }} </span> 元
          <br />
          <br />
          成果采纳 <span :style="{margin: '0 6px'}"
                v-if="demandObj.detail"> {{ demandObj.detail.mon3 }} </span> 元/项目
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            作品形式
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          初稿：手绘稿或电脑稿、文件格式、尺寸要求、分辨率等，要求原创、无抄袭、版权说明。
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              作品形式
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p v-if="demandObj.detail"> {{ demandObj.detail.xingshi }}</p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            知识产权
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              知识产权
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p> 参赛作品是自有产权，没有产权纠纷等 </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            企业提供材料下载
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              材料类型
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p style="cursor: pointer"
               v-if="demandObj.detail"
               @click="dowload"> {{ demandObj.detail.cailiao }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'enter',
  props: {
  },
  data () {
    return {
      demandObj: {
        company_name: '',
        company_description: '',
        title: '',
        contact_name: '',
        contact_phone: '',
        contact_address: '',
        dataUrl: '',
        thumbnail: '',
        design_type2: '',
        project_type2: '',
      }
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') === 'en') {
      this.$router.push({
        name: 'building'
      })
      return;
    }
    this.$api.getDesign_demand_detail({
      id: this.$route.params.id
    }).then(res => {
      this.demandObj = res;
      this.designType = [res.design_type];
      this.projectType = [res.project_type];
    })
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    dowload () {
      window.open('/api/static/' + this.demandObj.certificates.dataUrl);
    }
  },
  components: {
  }
}
</script>
